import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "./AddExchangeRates.css";
import { GetFXRatesApi } from "../../apis/FxRatesApi";
import { decrypytdata } from "../../apis/walletKey";
import { modifyWallet } from "../../apis/wallet";
import { setResponse } from "../../store/slices/responseSlice";
import { setLoader } from "../../store/slices/loaderSlice";
import { setUser } from "../../store/slices/userSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function AddExchangeRates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);
  const { toggleModal } = useContext(ModalOverlayContext);

  const [fxRates, setFxRates] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // console.log(fxRates);

  const [currencyCodeList, setCurrencyCodeList] = useState(
    userData?.CurrencycodeList?.split?.(",")?.filter?.((row) => row) || []
  );

  const APPID = userData.PublicAddress;
  const APIKEY = userData.PrivateKey;
  const selectedCurrencies = currencyCodeList.join(",");

  useEffect(() => {
    getFxRatesAPI();
  }, []);

  async function getFxRatesAPI() {
    try {
      dispatch(setLoader(true));

      const response = await GetFXRatesApi(APPID, APIKEY, {
        currencyPairTypeID: 1
      });

      const fxRatesJSON = await decrypytdata(response?.data, APIKEY);

      const fxRatesObj = JSON.parse(fxRatesJSON);

      let data = [];

      if (fxRatesObj.CommandResponse) {
        data = JSON.parse(fxRatesObj.CommandResponse);
      }

      // console.log(data);

      setFxRates(() => data);
    } catch (error) {
      if (error.response) {
        const fxRatesJSON = await decrypytdata(error.response?.data, APIKEY);

        if (!fxRatesJSON) return;

        const dataJSON = JSON.parse(fxRatesJSON);

        let data = [];

        if (dataJSON?.CommandResponse) {
          data = JSON.parse(dataJSON?.CommandResponse);
        }
      } else {
        console.log(error);
      }
    } finally {
      dispatch(setLoader(false));
    }
  }

  async function addCurrencyCodeList(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      const response = await modifyWallet(APPID, APIKEY, {
        currencycodeList: selectedCurrencies
      });

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      const decryptedData = await decrypytdata(response.data, APIKEY);

      const data = JSON.parse(decryptedData);

      if (data.ReturnKeycode === "Success") {
        const actualData = JSON.parse(data.CommandResponse);
        const newWalletData = {
          ...userData,
          CurrencycodeList: actualData.CurrencycodeList
        };

        dispatch(setUser(newWalletData));
        localStorage.setItem("user", JSON.stringify(newWalletData));

        dispatch(setResponse({ apiResponse: responseAPI, apiData: data }));
      } else {
        dispatch(setResponse({ apiResponse: responseAPI, apiData: data }));
      }

      navigate("/result");
    } catch (err) {
      console.log(err);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/fx_rates" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Add Exchange Rates</h3>
        </div>
        <div className="table-bg mt-4">
          {!isLoading && (
            <>
              <div className="table-height">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center">Country</th>
                      <th className="text-center">Currency</th>
                      <th className="text-end">
                        USD
                        <br />
                        Rate
                      </th>
                      <th className="text-end">
                        UMU
                        <br />
                        Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fxRates &&
                      fxRates.length > 0 &&
                      fxRates.map((rate) => (
                        <tr key={rate.RowID}>
                          <td>
                            <div>
                              <input
                                type="checkbox"
                                checked={currencyCodeList.includes(
                                  rate.Currencycode
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setCurrencyCodeList((prev) => [
                                      ...prev,
                                      rate.Currencycode
                                    ]);
                                  } else {
                                    setCurrencyCodeList((prev) =>
                                      prev.filter(
                                        (val) => val !== rate.Currencycode
                                      )
                                    );
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <img
                              src={rate.QuoteImageFN}
                              alt="flag"
                              style={{ width: "32px" }}
                            />
                          </td>
                          <td className="text-center">{rate.Currencycode}</td>
                          <td className="text-end">
                            <div className="d-flex justify-content-between">
                              <span>{rate.QuoteCurrencySymbol}</span>
                              <span>{Number(rate.BasePrice).toFixed(2)}</span>
                            </div>
                          </td>
                          <td className="text-end">
                            <div className="d-flex justify-content-between">
                              <span>{rate.QuoteCurrencySymbol}</span>
                              <span>{Number(rate.CoinPrice).toFixed(6)}</span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="text-center mt-4">
                <button
                  className="btn-sound btn next-btn next-btn-border me-3 mt-0"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  CANCEL
                </button>
                <button
                  className="btn-sound next-btn mt-4"
                  data-color="#3361a3"
                  data-text="SUBMIT"
                  onClick={(e) => addCurrencyCodeList(e)}
                >
                  SUBMIT
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

/* 

<table class="table table-borderless">
            <thead>
              <tr>
                <th></th>
                <th className="text-center">Country</th>
                <th className="text-center">Currency</th>
                <th className="text-end">USD Rate</th>
                <th className="text-end">UMU Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" />
                  </div>
                </td>
                <td className="text-center">
                  <img src="/icons/flags/US-flag.png" alt="flag" />
                </td>
                <td className="text-center">USD</td>
                <td className="text-end">$ 1.00</td>
                <td className="text-end">$ 3.400000</td>
              </tr>
              <tr>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" />
                  </div>
                </td>
                <td className="text-center">
                  <img src="/icons/flags/US-flag.png" alt="flag" />
                </td>
                <td className="text-center">USD</td>
                <td className="text-end">$ 1.00</td>
                <td className="text-end">$ 3.400000</td>
              </tr>
              <tr>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" />
                  </div>
                </td>
                <td className="text-center">
                  <img src="/icons/flags/US-flag.png" alt="flag" />
                </td>
                <td className="text-center">USD</td>
                <td className="text-end">$ 1.00</td>
                <td className="text-end">$ 3.400000</td>
              </tr>
            </tbody>
          </table>
*/
