import { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import "./TransferAmount.css";
import { TO_UMU } from "../../utils/conversion";
import { FROM_UMU } from "../../utils/conversion";

import SolicitAmount from "../../components/SolicitAmount";

export default function TransferAmount() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector((state) => state.loader);
  const { data: userData } = useSelector((state) => state.user);

  const [validationError, setValidationError] = useState("");
  const [payeeData] = useState(location?.state?.payeeData || null);

  const [walletFxRates, setWalletFxRates] = useState(null);
  const [settlementFxRates, setSettlementFxRates] = useState(null);
  const [enteredAmount, setEnteredAmount] = useState(0);
  const [umuAmount, setUmuAmount] = useState(0);
  const [walletAmount, setWalletAmount] = useState(0);

  if (!payeeData) {
    return <Navigate to="/payees" replace={true} />;
  }

  function validate(amount) {
    if (!amount) return "Amount is required";
    if (+amount < 1) return "Invalid amount";
    else if (isNaN(Number(amount))) return "Amount should be a valid number";
    /* else if (
      Number(amount) > Number(FROM_UMU(settlementFxRates?.CoinPrice, Balance))
    )
      return "Insufficient Balance"; */

    return null;
  }

  function handlePayClick() {
    const error = validate(enteredAmount);

    if (error) {
      setValidationError(error);
      return;
    }

    const transfer_summary = {
      refersTo: "originator",
      SourcePublicAddress: userData.PublicAddress,
      SourceDisplayname: userData.Displayname,
      TargetPublicAddress: payeeData.PublicAddress,
      TargetDisplayname: payeeData.Displayname,

      wallet_currency_code: walletFxRates.QuoteCurrencycode,
      wallet_quote_price: Number(walletFxRates.QuotePrice)?.toFixed?.(6, "0"),
      wallet_umu_units: Number(walletAmount)?.toFixed?.(6, "0"),

      settlement_currency_code: settlementFxRates?.QuoteCurrencycode,
      settlement_quote_price: Number(settlementFxRates?.QuotePrice)?.toFixed?.(
        6,
        "0"
      ),
      settlement_umu_units: Number(umuAmount)?.toFixed?.(6, "0"),

      umu_transaction_fee: 0.0,
      amount: Number(enteredAmount)?.toFixed?.(6, "0")
    };

    navigate("/transfer_review", { state: transfer_summary });
  }

  function handleEscrowClick() {
    const error = validate(enteredAmount);

    if (error) {
      setValidationError(error);
      return;
    }

    const transfer_summary = {
      refersTo: "originator",
      SourcePublicAddress: userData.PublicAddress,
      SourceDisplayname: userData.Displayname,
      TargetPublicAddress: payeeData.PublicAddress,
      TargetDisplayname: payeeData.Displayname,

      wallet_currency_code: walletFxRates.QuoteCurrencycode,
      wallet_quote_price: Number(walletFxRates.QuotePrice)?.toFixed?.(6, "0"),
      wallet_umu_units: Number(walletAmount)?.toFixed?.(6, "0"),

      settlement_currency_code: settlementFxRates?.QuoteCurrencycode,
      settlement_quote_price: Number(settlementFxRates?.QuotePrice)?.toFixed?.(
        6,
        "0"
      ),
      settlement_umu_units: Number(umuAmount)?.toFixed?.(6, "0"),

      umu_transaction_fee: 0.0,
      amount: Number(enteredAmount)?.toFixed?.(6, "0")
    };

    navigate("/escrow_create", { state: transfer_summary });
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link onClick={() => navigate(-1)} role="button" className="arrow-bg">
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3 className="ms-1">Settlement Currency Amount</h3>
        </div>

        {!isLoading && (
          <div className="enter-amount">
            {validationError && (
              <p className="error_message text-danger">{validationError}</p>
            )}
            <SolicitAmount
              walletFxRates={walletFxRates}
              settlementFxRates={settlementFxRates}
              enteredAmount={enteredAmount}
              setWalletFxRates={setWalletFxRates}
              setSettlementFxRates={setSettlementFxRates}
              setEnteredAmount={setEnteredAmount}
              setUmuAmount={setUmuAmount}
              setWalletAmount={setWalletAmount}
            />

            <div className="text-center d-flex align-items-center">
              <button
                className="btn-sound next-btn me-2 w-100"
                to="/"
                onClick={handlePayClick}
              >
                PAY
              </button>
              <button
                className="btn-sound next-btn w-100"
                onClick={handleEscrowClick}
              >
                ESCROW
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
