import "../wallet/Payees.css";
import "./AddUMU.css";
import { Link, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPayees } from "../../apis/payees";
import { decrypytdata } from "../../apis/walletKey";
import { GetWalletLinks } from "../../apis/wallet";
import { setLoader } from "../../store/slices/loaderSlice";
import CurrencyInput from "react-currency-input-field";

export default function AddUMU() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);

  const [search, setSearch] = useState("");
  // const [payeeList, setPayeeList] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [filteredList, setFilteredList] = new useState([]);

  const [cryptoList, setCryptoList] = useState([]);

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        await getCryptoList();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  const APPID = userData?.PublicAddress;
  const APIKEY = userData?.PrivateKey;

  const filterBySearch = (event) => {
    const query = event.target.value;
    setSearch(query);
    var updatedList = [...cryptoList];
    updatedList = updatedList.filter((item) => {
      return (
        item?.Displayname?.toLowerCase?.()?.indexOf(query?.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };

  async function getCryptoList() {
    const response = await GetWalletLinks(APPID, APIKEY, {
      walletLinkTypecode: "CRYPTO"
    });

    const decryptedResponse = await decrypytdata(response.data, APIKEY);
    const responseObj = JSON.parse(decryptedResponse);

    // console.log(responseObj);

    if (
      responseObj &&
      (responseObj.ReturnKeycode === "Success" ||
        responseObj.ReturnMessage === "Success")
    ) {
      const crptoDataList = JSON.parse(responseObj.CommandResponse || "[]");

      // console.log(crptoDataList);

      setCryptoList(crptoDataList);
      setFilteredList(crptoDataList);
    }
  }

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <p
            role="button"
            className="btn-sound arrow-bg"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-arrow-left-short"></i>
          </p>
          <h3>ADD FUNDS</h3>
          {/* <h3>ADD UMU</h3> */}
        </div>

        {!isLoading ? (
          <div className="payees-content">
            <p className="mt-3 mb-0 text-white" style={{ fontSize: "12px" }}>
              You can exchange any supported crypto by attaching it to your
              wallet and sending funds to the listed wallet address.
            </p>

            {/* <h6>Search Cryptocurrencies</h6> */}
            <h6 className="text-center">Select Cryptocurrency for Payment</h6>

            {/* <div className="d-flex mb-4">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={search}
                  onChange={filterBySearch}
                />
              </div>
              <div className="plus-icon ms-3">
                <Link
                  to="/attach"
                  className="text-white"
                  state={{ WalletLinkTypecode: "CRYPTO" }}
                >
                  <i className="bi bi-plus-lg"></i>
                </Link>
              </div>
            </div> */}

            {filteredList && filteredList.length > 0 ? (
              <>
                <div className="table-bg table-content mt-0">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: "10px" }}></th>
                        {/* <th>Cryptocurrency</th> */}
                        <th></th>
                        <th>Payment Wallet Address</th>
                        <th style={{ width: "10px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.map((item, idx) => {
                        return (
                          <Fragment key={idx}>
                            <tr
                              id="row_container"
                              className="crypto_list_item"
                              onClick={() =>
                                navigate("/crypto_amount", {
                                  state: { ...item }
                                })
                              }
                            >
                              <td className="border-0">
                                <img
                                  className="payee_image"
                                  // src="/images/default.png"
                                  src={`images/crypto/${item.ImageFN}`}
                                  alt="default-img"
                                />
                              </td>
                              <td className="border-0">
                                <p className="payee_name mb-0">
                                  {item.Displayname}
                                </p>
                              </td>
                              <td className="border-0">
                                <span style={{ lineBreak: "anywhere" }}>
                                  {item.NetworkWalletAddress}
                                </span>
                              </td>
                              <td className="border-0">
                                <div
                                  className="table-icon"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.target
                                      .closest(".table-icon")
                                      .classList.add("bg-success");
                                    navigator.clipboard.writeText(
                                      item.NetworkWalletAddress
                                    );
                                    setTimeout(
                                      () =>
                                        e.target
                                          .closest(".table-icon")
                                          .classList.remove("bg-success"),
                                      3000
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-copy"></i>
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="email-address p-0 identification">
                  <div className="container">
                    <div className="error-conten">
                      <img src="/icons/alert-triangle.png" alt="" />
                      <p>No Payees Found</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

{
  /* <div key={idx} className="payees-list mb-2">
                          <div>
                            <img
                              className="payee_image"
                              src="/images/default.png"
                              alt="default-img"
                            />
                          </div>

                          <img
                            src={
                              li?.Countrycode
                                ? `/icons/flags/${li?.Countrycode?.toLowerCase()}.png`
                                : "/icons/flags/US-flag.png"
                            }
                            className="ms-2 me-2"
                            alt="flag"
                            style={{ width: "32px" }}
                          />
                          <p className="mb-0 ms-2">{li.Countrycode}</p>

                          <p className="payee_name mb-0 ms-2">
                            {li.Displayname}
                          </p>
                        </div> */
}
