import { useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";

import { generatePdf } from "../../utils/pdf";
import { uploadFile } from "../../apis/upload-download";
import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function NoteAgreement() {
  const componentRef = useRef();

  const navigate = useNavigate();

  const { cashReserveNotes, setCashReserveNotes } = useOutletContext();

  const { toggleModal } = useContext(ModalOverlayContext);

  const { data: userData } = useSelector((state) => state.user);

  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });

  async function handleButtonClick(e) {
    try {
      showLoaderOnElement(e.target);
      toggleModal(true);

      const data = await generatePdf(componentRef.current);

      const formData = new FormData();
      formData.append("File", data, `note_agreement.pdf`);
      formData.append("PublicAddress", userData?.PublicAddress);
      formData.append("IsPublic", false);

      const response = await uploadFile(formData);

      if (response?.data?.returnKeycode !== "Success") throw response;

      setCashReserveNotes({
        ...cashReserveNotes,
        documentFn: response?.data?.commandResponse
      });

      navigate("/cash_reserve_notes_add");
    } catch (error) {
      console.error(error);
    } finally {
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <div className="mb-0 email-address pt-0 pb-0 text-white">
      <div className="container identification">
        <div className="header">
          <div>
            <p
              className="arrow-bg p-2"
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <i className="bi bi-arrow-left-short"></i>
            </p>
          </div>
        </div>

        <div ref={componentRef}>
          <div>
            <h3 className="mt-3 title-heading text-center">
              UNIVERSAL MONETARY UNIT CORPORATION
            </h3>
            <h6 className="mb-4 text-center">CASH RESERVE NOTE AGREEMENT</h6>
          </div>

          <div className="mt-3 texts">
            <div>
              <p>
                <strong>Date: {currentDate}</strong>
              </p>
              <p>
                FOR VALUE RECEIVED, (“<strong>Funds</strong>”), Universal
                Monetary Unit Corporation ("<strong>UMU</strong>"), a
                Washington, D.C. Corporation, located at 300 New Jersey Avenue
                NW, Suite 900, Washington, D.C. 20001, shall calculate the
                principal value (“Principal”) as defined in Section 2(d) and
                promises to pay the funder ("<strong>Holder</strong>"), the sum
                of Principal with interest to begin accruing seven (7) calendar
                days after the Funds has a confirmed receipt by our bank or
                financial institution for this (“<strong>Note</strong>”).
              </p>
            </div>

            <div>
              <p>
                <strong>
                  1. <u>Interest Payable</u>
                </strong>
              </p>

              <p>
                (a) Subject to Section 1(c) below, the outstanding Principal
                shall bear simple interest at the rate of (<strong>6.0%</strong>
                ), computed based on the actual number of days elapsed and a
                year of 365 days. Payments pursuant to this Note will be applied
                first to accrued interest and then to outstanding Principal.
              </p>

              <p>
                (b) Accrued interest on this Note shall be payable biannually,
                or every six (6) calendar months, within seven (7) calendar days
                of the biannual date of the first day of interest accrual on
                this Note.{" "}
              </p>

              <p>
                (c) If any amounts payable under this Note are not paid within
                thirty (30) calendar days after the due date, interest shall
                accrue on the Principal in an amount equal to ten percent (8.0%)
                per annum (the “<strong>Default Rate</strong>”).
              </p>
            </div>

            <div>
              <p>
                <strong>
                  2. <u>Terms of Repayment</u>
                </strong>
              </p>

              <p>
                (a) After a minimum of five (5) calendar years from the first
                day of interest accrual on this Note, the unpaid Principal and
                accrued interest shall be payable in full, or in part, at the
                specified amount.
              </p>

              <p>
                (b) After a minimum of one (1) calendar year from the first day
                of interest accrual on this Note, UMU may pay this Note in whole
                or in part without penalty or premium at any time prior to
                maturity of this Note.
              </p>

              <p>
                (c) All accrued interest will be paid at the equivalent value of
                Universal Monetary Unit (“Unicoin”) at the current market rate
                at the time of earned payments and will automatically appear in
                the Holder’s Unicoin Cash Wallet balance. Unicoin can be
                liquidated to cash at the discretion of the Holder at any time.
              </p>

              <p>
                (d) For Funds wired in U.S. dollars (USD), the Principal shall
                equal the amount wired less any bank wiring fees. Any Funds not
                wired in U.S. dollars, such as cryptocurrency or a safe-keeping
                receipt (SKR), the Funds shall be converted to USD for
                determining the Principal. Any transportation, conversion, or
                third-party handling fees shall be deducted from the Funds to
                calculate the Principal. In no event shall applicable fees
                exceed three percent (3%) of the funded amount.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  3. <u>Cash-Backed Assurances</u>
                </strong>
              </p>

              <p>
                (a) After a minimum of five (5) calendar years from the first
                day of interest accrual on this Note, the unpaid Principal and
                accrued interest shall be payable in full, or in part, at the
                specified amount.
              </p>

              <p>
                As of the date cash or cash equivalent are first placed with the
                UMU, UMU shall maintain free and clear of all liens and shall
                maintain one of the following in direct security of the Note:
                (a) readily marketable direct obligations of the Government of
                the United States or any agency or instrumentality thereof or
                obligations unconditionally guaranteed by the full faith and
                credit of the Government of the United States, (b) readily
                marketable direct obligations of any member of the G20, or any
                agency or instrumentality thereof or obligations unconditionally
                guaranteed by the full faith and credit of such country, and, at
                the time of acquisition thereof having an equivalent credit
                rating of at least AA- by Moody’s or Aa3 by S&P.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  4. <u>No Waiver</u>
                </strong>
              </p>

              <p>
                (a) The obligation by UMU to make the payments provided for in
                this Note are absolute and unconditional and not subject to any
                defense, set-off, counterclaim, rescission, recoupment, or
                adjustment whatsoever. The UMU hereby expressly waives demand
                and presentment for payment, notice of non-payment, notice of
                dishonor, protest, notice of protest, bringing of suit, and
                diligence in taking any action to collect any amount called for
                hereunder, and shall be directly and primarily liable for the
                payment of all sums owing and to be owing hereon, regardless of,
                and without any notice, diligence, act, or omission with respect
                to, the collection of any amount called for hereunder.
              </p>

              <p>
                (b) A waiver of one event shall not be construed as continuing
                or as a bar to or waiver of such right, remedy or power upon a
                subsequent event.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  5. <u>No Transfers</u>
                </strong>{" "}
                This Note may not be sold or transferred by either Holder or UMU
                (Holder and UMU, each a “<strong>Note Party</strong>” and
                together, the “<strong>Note Parties</strong>”).
              </p>
            </div>

            <div>
              <p>
                <strong>
                  6. <u>Representations And Warranties Of Note Parties</u>
                </strong>{" "}
                As a condition precedent to the making of this Note, each Note
                Party, jointly and severally, represents and warrants that:
              </p>

              <p>
                <strong>(a) Due Organization and Authorization.</strong> Each
                Note Party’s exact legal name is as set forth in the Note and
                each Note Party is, and will remain, duly organized, existing
                and, in good standing. This Agreement has been duly authorized,
                executed and delivered by each Note Party and constitutes a
                legal, valid and binding agreement enforceable in accordance
                with their terms subject to applicable bankruptcy, insolvency,
                reorganization, moratorium or other laws affecting creditors’
                rights generally and subject to general principles of equity,
                regardless of whether considered in a proceeding in equity or at
                law and the time barring of claims and defenses of set-off or
                counterclaim. The execution, delivery and performance of this
                Note is within such Note Party’s powers.
              </p>

              <p>
                Solely for purposes of this Note, the term “
                <strong>Material Adverse Effect</strong>” shall mean a material
                adverse effect on any of (i) the operations, business, assets,
                properties, or financial condition of the Note Parties, taken as
                a whole, (ii) the ability of a Note Party to perform any of its
                obligations under this Note to which it is a party, (iii) the
                legality, validity or enforceability of this Note and (iv) the
                rights and remedies of any Note Party under this Note.
              </p>

              <p>
                <strong>(b) Required Consents.</strong> No filing, registration,
                qualification with, approval, consent or withholding of
                objections from any governmental authority, instrumentality, or
                any other entity or person is required with respect to the entry
                into or performance of any of the Note.
              </p>

              <p>
                <strong>(c) No Conflicts.</strong> The entry into, and
                performance by each Note Party of, the Note will not (a) violate
                any of the constituent or organizational documents of such Note
                Party, (b) violate any material law, rule, regulation, order,
                award or judgment applicable to such Note Party, or (c) result
                in any breach of or constitute a default under, or result in the
                acceleration of any obligation or creation of any lien, claim or
                encumbrance on any of such Note Party’s property pursuant to,
                any indenture, mortgage, deed of trust, bank Note, or credit
                agreement to which such Note Party is a party.
              </p>

              <p>
                <strong>(d) Litigation.</strong> There are no actions, suits,
                proceedings or investigations pending against any Note Party
                before any court, federal, state, provincial, municipal or other
                governmental department, commission, board, bureau, agency or
                instrumentality, domestic or foreign, or any basis thereof, that
                could reasonably be expected to have a Material Adverse Effect
                if adversely determined to any Note Party, or which questions
                the validity of the Note, or any action to be taken pursuant to
                any of the foregoing.
              </p>

              <p>
                <strong>(e) Restrictions on Use of Funds.</strong> Holder
                restricts and UMU accepts the use of funds from this Note shall
                be used strictly (i) for currency conversion to another foreign
                currency for the purposes of settling cross-border payments, and
                (ii) for settling cross-border payments on at least an
                equivalent amount of cash being tendered by a third party
                resulting in an equivalent or stronger financial condition of
                UMU, (iii) for providing intermediary trade financing services
                on commodity purchases resulting in an equivalent or stronger
                financial condition of UMU, (iv) for providing liquidity to
                sellers of Unicoin, and (v) for risk-mitigating hedging
                activities exempt by the Commodities Futures and Trading
                Commission (CFTC) from registration including applicable
                proprietary system hedging against market risks, counterparty or
                other credit risks, foreign exchange risks, interest rate risks,
                commodity price risks, basis risks, or similar risks, arising in
                connection with the restrictive use of funds identified in this
                Section 6e.
              </p>

              <p>
                Beyond the above stated cash-flow and working capital needs, the
                funds shall not be used for any general business use or to
                finance significant business investments, including capital
                expenditures. Any additional use of funds is strictly prohibited
                unless granted in writing by Holder.
              </p>

              <p>
                <strong>(f) Compliance with Laws.</strong> Each Note Party is
                and will remain in compliance in all material respects with (i)
                all U.S. economic sanctions laws, Executive Orders and
                implementing regulations as promulgated by the U.S. Treasury
                Department’s Office of Foreign Assets Control (“
                <strong>OFAC</strong>”) and (ii) all applicable anti-money
                laundering and counter-terrorism financing provisions of the
                Bank Secrecy Act and the USA PATRIOT Act, and all regulations
                issued pursuant to it. No Note Party nor any of its subsidiaries
                or joint ventures, (A) is a person or entity designated by the
                U.S. Government on the list of the Specially Designated
                Nationals and Blocked Persons (the “<strong>SDN List</strong>”)
                with which a U.S. person or entity cannot deal with or otherwise
                engage in business transactions (B) is a person or entity who is
                otherwise the target of U.S. economic sanctions laws such that a
                U.S. person or entity cannot deal or otherwise engage in
                business transactions with such person or entity; or (C) is
                controlled by (including without limitation by virtue of such
                person being a director or owning voting shares or interests),
                or acts, directly or indirectly, for or on behalf of, any person
                or entity on the SDN List or a foreign government that is the
                target of U.S. economic sanctions prohibitions such that the
                entry into, or performance under, this Note would be prohibited
                under U.S. law. The SDN List is maintained by OFAC and is
                available at:
                <a
                  href="http://www.ustreas.gov/offices/enforcement/ofac/sdn/"
                  target="_blank"
                >
                  http://www.ustreas.gov/offices/enforcement/ofac/sdn/
                </a>
                . In addition, each Note Party is and will remain in compliance
                with all other laws, statutes, ordinances, rules and regulations
                applicable to it except where failure to do so will not have a
                Material Adverse Effect.
              </p>

              <p>
                <strong>(g) Solvency.</strong> Both before and after giving
                effect to this Note, each Note Party is and will be Solvent. As
                used herein, “Solvent” means, with respect to any Note Party on
                a particular date, that on such date (a) the fair value of the
                property of such Note Party is greater than the total amount of
                liabilities, including contingent liabilities, of such Note
                Party; (b) the present fair salable value of the assets of such
                Note Party is not less than the amount that will be required to
                pay the probable liability of such Note Party on its debts as
                they become absolute and matured; (c) such Note Party does not
                intend to, and does not believe that it will, incur debts or
                liabilities beyond such Note Party’s ability to pay as such
                debts and liabilities mature; (d) such Note Party is not engaged
                in a business or transaction, and is not about to engage in a
                business or transaction, for which such Note Party’s property
                would constitute an unreasonably small capital; and (e) is not
                “insolvent” within the meaning of Section 101(32) of the United
                States Bankruptcy Code (11 U.S.C. § 101, et. seq), as amended
                from time to time.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  7. <u>Events Of Default; Remedies</u>
                </strong>
              </p>

              <p>
                (a) The occurrence of any of the following events shall
                constitute an Event of Default. Solely for purposes of this
                Note, an “<strong>Event of Default</strong>” shall mean: (i) the
                nonpayment by UMU of any Principal, interest or other amounts
                owing under this Note when due; (ii) the material breach by UMU
                any representation, warranty or covenant in this Note; (iii) the
                filing by or against UMU of any proceeding in bankruptcy,
                receivership, insolvency, reorganization, liquidation,
                conservatorship or similar proceeding (and, in the case of any
                such proceeding instituted against UMU, such proceeding is not
                dismissed or stayed within forty-five (45) days of the
                commencement thereof); (iv) any assignment by UMU for the
                benefit of creditors, or any levy, garnishment, attachment or
                similar proceeding is instituted against any property of UMU;
                (v) a default with respect to any other material indebtedness of
                UMU for borrowed money (taking into account any applicable grace
                and cure periods), if the effect of such default is to cause or
                permit the acceleration of such debt; (vi) the entry of a final
                judgment against UMU and the failure of UMU to discharge the
                judgment within forty-five (45) days of the entry thereof; or
                (vii) UMU ceases doing business as a going concern.
              </p>

              <p>
                (b) Upon the occurrence of an Event of Default: (i) if an Event
                of Default specified in clause (a)(iii) or (a)(iv) above shall
                occur, the outstanding principal balance and accrued interest
                hereunder together with any additional amounts payable hereunder
                shall be immediately due and payable without demand or notice of
                any kind; (ii) if any other Event of Default shall occur, the
                outstanding principal balance and accrued interest hereunder
                together with any additional amounts payable hereunder, at
                Holder’s option and without demand or notice of any kind, may be
                accelerated and become immediately due and payable; (iii) at
                Holder’s option, this Note will bear interest at the Default
                Rate from the date of the occurrence of the Event of Default;
                and (iv) Holder may exercise from time to time any rights and
                remedies under applicable law (including initiating legal action
                for collection of this Note).
              </p>
            </div>

            <div>
              <p>
                <strong>
                  8. <u>Indemnity</u>
                </strong>
                . Each Note Party agrees to indemnify the other Note Party, its
                managers, officers and members (the “
                <strong>Indemnified Parties</strong>”), and to defend and hold
                each Indemnified Party harmless from and against any and all
                claims, damages, losses, liabilities and expenses (including
                reasonable attorney’s costs and fees with whom any Indemnified
                Party may consult and all expenses of litigation and preparation
                therefor) which any Indemnified Party may incur or which may be
                asserted against any Indemnified Party by any person, entity or
                governmental authority (including any person or entity claiming
                derivatively on behalf of a Note Party), in connection with or
                arising out of or relating to the matters referred to in this
                Note or the use of funds advanced hereunder, whether (a) arising
                from or incurred in connection with any breach of a
                representation, warranty or covenant by a Note Party, or (b)
                arising out of or resulting from any suit, action, claim,
                proceeding or governmental investigation, pending or threatened,
                whether based on statute, regulation or order, or tort, or
                contract or otherwise, before any court or governmental
                authority; provided, that the foregoing indemnity obligation
                shall not apply to any claims, damages, losses, liabilities and
                expenses solely attributable to an Indemnified Party’s gross
                negligence or willful misconduct. The indemnity obligation
                contained in this Section 8 shall survive the termination of
                this Note, payment of the Principal hereunder and the assignment
                of any rights hereunder.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  9. <u>Affirmative Covenants</u>
                </strong>
                . From the date hereof and until payment and performance in full
                of the Note, UMU hereby covenants and agrees with Holder that:
              </p>

              <p>
                (a) UMU shall comply in all material respects with all
                applicable laws comply in all material respects with laws,
                rules, regulations and requirements applicable to its business.
              </p>

              <p>
                (b) UMU shall prepare all tax returns required by law to be
                filed, and such returns shall be accurate in all material
                respects.{" "}
              </p>

              <p>
                (c) UMU shall provide written notice to Holder of the occurrence
                of any of the following (together with a description of the
                action which UMU proposes to take with respect thereto): (i) any
                Event of Default or any event, act or condition which, with the
                passage of time or the giving of notice, or both, would
                constitute an Event of Default; (ii) any material litigation
                filed by or against UMU; or (iii) any event that could
                reasonably be expected to have a Material Adverse Effect on the
                business, assets, operations, condition (financial or otherwise)
                or results of operation of UMU.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  10. <u>Negative Covenants</u>
                </strong>
                . From the date hereof and until payment and performance in full
                of the Note, UMU hereby covenants and agrees with Holder that:
              </p>

              <p>
                (a) UMU shall not liquidate or dissolve, merge or consolidate
                with or into any other entity; sell, lease, transfer or
                otherwise dispose of all or a substantial part of its property,
                assets, operations or business, whether now owned or hereafter
                acquired; or permit a change in the ownership of its equity
                interests.
              </p>

              <p>
                (b) UMU shall not create, incur, assume or permit to exist any
                material indebtedness except for (i) the Note, (ii) trade
                payables incurred in the ordinary course of business, (iii)
                indebtedness existing as of the date of this Note and (iv)
                indebtedness consented to by Holder, and any refinancing of such
                indebtedness in amounts not in excess of the amounts outstanding
                when refinanced and on term and conditions reasonably acceptable
                to Holder.
              </p>

              <p>
                (c) UMU shall not directly or indirectly enter into, or permit
                to exist, any material transaction with any affiliate of UMU,
                except for transactions that are in the ordinary course of the
                business of UMU and are on fair and reasonable terms that are no
                less favorable to UMU than would be obtained in an arm’s-length
                transaction with a non-affiliate.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  11. <u>Costs and Attorneys’ Fees</u>
                </strong>
                . Each Note Party shall bear its own fees and costs, including,
                without limitation, all attorneys’ fees.
              </p>
            </div>

            <div>
              <p>
                <strong>
                  12. <u>Miscellaneous.</u>
                </strong>
              </p>

              <p>
                (a) The funding of this Note shall be paid in the legal currency
                of the United States or an available equivalent currency
                supported by the UMU. All payments of principal and interest
                shall be paid in the legal currency of the United States or an
                available equivalent currency supported by the UMU.
              </p>

              <p>
                (b) All rights of Holder under this Note are cumulative and may
                be exercised concurrently or consecutively at Holder’s option.
              </p>

              <p>
                (c) This Note shall be construed in accordance with the laws of
                the Washington, District of Columbia, without regard to conflict
                of law provisions.
              </p>

              <p className="text-center">
                <em>[Signature Page Follows]</em>
              </p>

              <br />
              <br />
            </div>

            <div>
              <p>
                <strong>
                  I agree and understand my electronic signature herein is the
                  legal equivalent of my handwritten signature and I consent to
                  be legally bound to this agreement.
                </strong>
              </p>

              <p>
                <strong>BY ELECTRONIC SIGNATURE</strong>, this Agreement has
                been executed and delivered in the manner prescribed by law as
                of the date first written above.
              </p>

              <p>
                <strong>HOLDERS:</strong>
              </p>

              <p>Business Name: {userData?.Businessname}</p>

              <p>Signatory Name: {userData?.Displayname}</p>

              <p>Address: {userData?.FullAddress}</p>

              <p>
                By:{" "}
                <span style={{ fontFamily: "Satisfy" }}>
                  {userData?.Displayname?.split?.(" ")?.join?.("")}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div>
          <button
            type="button"
            // className="btn-sound next-btn mt-0"
            onClick={(e) => handleButtonClick(e)}
            style={{ width: "100%", margin: "16px 0" }}
            className="btn-sound btn next-btn"
            data-color="#3361a3"
            data-text="CONTINUE"
            // onClick={(e) => handleContinue(e)}
          >
            ACCEPT AND CONTINUE
          </button>
        </div>
      </div>
    </div>
  );
}
