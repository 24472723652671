import html2pdf from "html2pdf.js";

export async function generatePdf(element) {
  const componentHtml = element.innerHTML;

  return html2pdf()
    .set({ html2canvas: { scale: 4 }, margin: [10, 20, 10, 20] })
    .from(componentHtml)
    .outputPdf("blob")
    .then((blob) => blob);
}
