import { decrypytdata } from "../apis/walletKey";

export async function decryptResponse(response, privateKey) {
  if (!response?.data) {
    throw new Error("Something went wrong!");
  }

  const decryptedCreateLoanResponse = await decrypytdata(response, privateKey);

  const decryptedCreateLoanResponseObject = JSON.parse(
    decryptedCreateLoanResponse
  );

  const responseConfig = {
    ...response,
    headers: undefined,
    request: undefined,
    config: {
      baseURL: response?.config?.baseURL,
      url: response?.config?.url
    }
  };

  return { decryptedCreateLoanResponseObject, responseConfig };
}
