import "../payments/TransferReview.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
/* import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; */

/* import pdf from "../../assets/test/test pdf.pdf"; */
import { useEffect, useState } from "react";
import axios from "axios";
import { UMU_URL } from "../../constants/config";
import { createMilestone, modifyMilestone } from "../../apis/milestone";
import { setLoader } from "../../store/slices/loaderSlice";
import { decrypytdata } from "../../apis/walletKey";
import { setResponse } from "../../store/slices/responseSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function CreateMilestoneSummary() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);
  const { toggleModal } = useContext(ModalOverlayContext);

  const [escrowSummary, setEscrowSummary] = useState({
    comments: "",
    referenceNumber: ""
  });

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;
  const { Escrow_Summary, item, milestone_summarypage } = location.state;
  // if (!escrowData || !transferData) {
  //   return <Navigate to="/escrow_create" replace={true} />;
  // }

  // const {
  //   shortDescription,
  //   // longDescription,
  //   expectedDeliveryDate,
  //   documentFN
  // } = escrowData;

  // const {
  //   refersTo,
  //   name,
  //   settlementCurrency,
  //   umuRate,
  //   umuAmount,
  //   walletCurrency,
  //   walletAmount,
  //   settlementAmount
  // } = transferData;

  // console.log(documentFN);

  /* useEffect(() => {
    downloadFile();
  }, []);

  const [f, setF] = useState(null); */

  async function downloadFile() {
    //     /* function convertToBase64(file) {
    //       return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);

    //         reader.onload = () => {
    //           console.log(reader.result);
    //           const base64 = reader.result;
    //           resolve(base64);
    //         };

    //         reader.onerror = () => {
    //           reject(new Error("Failed to convert the file to base64"));
    //         };
    //       });
    //     } */
    let documentFN = milestone_summarypage.documentFN;

    var req = new XMLHttpRequest();
    var uurrll =
      "https://protocol.umu.testnet.unicoinnetwork.com" +
      "/general/DownloadFile";
    req.open("POST", uurrll, true);
    req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    req.setRequestHeader(
      "Authorization",
      "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
    );
    req.send(
      JSON.stringify({
        fileName: documentFN,
        publicAddress: APPID
      })
    );
    req.responseType = "blob";
    req.onload = function () {
      if (documentFN.lastIndexOf(".pdf") > 0) {
        var blob = new Blob([req.response], { type: "application/pdf" });
        window.open(URL.createObjectURL(blob), "_blank");
      } else {
        var blob = new Blob([req.response], { type: "image/*" });

        const myFile = new File([blob], "image.jpeg", {
          type: blob.type
        });
        var link = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("href", link);
        a.setAttribute("download", documentFN);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      //     // const response = await axios({
      //     //   baseURL: UMU_URL,
      //     //   url: "/general/DownloadFile",
      //     //   method: "POST",
      //     //   headers: {
      //     //     Authorization:
      //     //       "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
      //     //   },
      //     //   data: {
      //     //     // fileName: "ec517bc4-1547-4618-a453-f65f3224e361test pdf.pdf",
      //     //     fileName: documentFN,
      //     //     publicAddress: APPID
      //     //   }
      //     // });
      //     // debugger
      //     // var file = new Blob([response.data], { type: "application/pdf" });
      //     // var files = new File([response.data], documentFN, {lastModified: 1534584790000});
      //     // return response.data;

      //     /* const res = await fetch(
      //       "https://protocol.umu.testnet.unicoinnetwork.com/general/DownloadFile",
      //       {
      //         fileName: "ec517bc4-1547-4618-a453-f65f3224e361test pdf.pdf",
      //         publicAddress: APPID
      //       }
      //     ).then((res) => {
      //       var file = new Blob([res], { type: "application/pdf" });
      //       var fileURL = URL.createObjectURL(file);
      //       setF(fileURL);
      //       console.log(fileURL);
      //     });

      //     return;

      //     return response.data;
      //     console.log(response.data); */
    };
  }

  const date = new Date(milestone_summarypage.expectedDeliveryDate);
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;

  /* const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  } */

  async function handleClick(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      const data = {
        txHASH: item.TxHash,
        milestoneTypecode: milestone_summarypage.milestoneTypecode,
        settlementCurrencycode: Escrow_Summary.settlement_currency_code,
        settlementUnits: Number(Escrow_Summary.amount).toFixed(3),
        shortDescription: milestone_summarypage.shortDescription,
        longDescription: milestone_summarypage.longDescription,
        referenceNumber: escrowSummary.referenceNumber,
        documentFN: milestone_summarypage.documentFN
          ? milestone_summarypage.documentFN
          : "",
        comment: escrowSummary.comments,
        expectedDeliveryDate: milestone_summarypage.expectedDeliveryDate
      };

      const response = location.state.item.isModify
        ? await modifyMilestone(APPID, APIKEY, data)
        : await createMilestone(APPID, APIKEY, data);
      const decryptedResponse = await decrypytdata(response.data, APIKEY);

      const decryptedResponseJson = JSON.parse(decryptedResponse);

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      dispatch(
        setResponse({
          apiResponse: responseAPI,
          apiData: decryptedResponseJson
        })
      );
      navigate("/result", { replace: true });

      console.log(decryptedResponseJson);
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  /* console.log(location.state);
  console.log(f); */

  // const [file, setFile] = useState(null);

  return (
    <div className="email-address p-0">
      {/* <object
        // data="https://www.africau.edu/images/default/sample.pdf"
        data={f}
        width="300"
        height="200"
      ></object> */}
      <div className="identification">
        <div className="container">
          <div className="header">
            <p
              /* to="/milestone_create" replace={true} */ role="button"
              onClick={() => navigate(-1, { replace: true })}
              className="arrow-bg"
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-arrow-left-short"></i>
            </p>
            <h3>Summary</h3>
          </div>

          <div className="summary-box">
            <div className="summary-box-logo">
              <img src="/images/summary-logo.png" alt="logo" />
            </div>

            <h1 className="text-center">Summary</h1>

            <div className="summary-list mb-3">
              {Escrow_Summary.refersTo === "originator" && <p>To</p>}
              {Escrow_Summary.refersTo === "recipient" && <p>From</p>}
              <h5 className=" mb-3">{Escrow_Summary.SourceDisplayname}</h5>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Settlement</p>
                {/* <h5>{"settlementCurrency"}</h5> */}
                <h5>{Escrow_Summary.settlement_currency_code}</h5>
              </div>
              <div className="summary-list text-end mb-3">
                <p>Settlement Amount</p>
                {/* <h5>{"settlementAmount"}</h5> */}
                <h5>{Number(Escrow_Summary.amount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>UMU Rate</p>
                {/* <h5>{"umuRate"}</h5> */}
                <h5>
                  {Number(Escrow_Summary.settlement_quote_price)?.toFixed(
                    6,
                    "0"
                  )}
                </h5>
              </div>

              <div className="summary-list mb-3 text-end">
                <p>UMU Amount</p>
                {/* <h5>{"umuAmount"}</h5> */}
                <h5>
                  {Number(Escrow_Summary.settlement_umu_units)?.toFixed(6, "0")}
                </h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Wallet</p>
                {/* <h5>{"walletCurrency"}</h5> */}
                <h5>{Escrow_Summary.wallet_currency_code}</h5>
              </div>

              <div className="summary-list mb-3 text-end">
                <p>Wallet Amount</p>
                {/* <h5>{"walletAmount"}</h5> */}
                <h5>
                  {Number(Escrow_Summary.wallet_umu_units)?.toFixed(6, "0")}
                </h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Milestone Short Description</p>
                <h5>{milestone_summarypage.shortDescription}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Milestone Long Description</p>
                <h5>{milestone_summarypage.longDescription}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Target Completion Date</p>
                <h5>{formattedDate}</h5>
              </div>
            </div>

            {/* <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Purchase Agreement</p> */}
            {/* <h5>{formattedDate}</h5> */}
            {/* <Document */}
            {/* //   file={downloadFile(documentFN)} */}
            {/* file={f}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} width={40} />
                </Document>
              </div>
            </div> */}
          </div>

          <div>
            <h6 className="mt-3 text-white">Escrow Information (Optional)</h6>
            <input
              type="text"
              className="form-control mt-3"
              placeholder="Reference Number"
              value={escrowSummary.referenceNumber}
              onChange={(e) =>
                setEscrowSummary((prev) => ({
                  ...prev,
                  referenceNumber: e.target.value
                }))
              }
            />

            <div>
              <textarea
                className="form-control mt-3"
                placeholder="Comments"
                rows="3"
                value={escrowSummary.comments}
                onChange={(e) =>
                  setEscrowSummary((prev) => ({
                    ...prev,
                    comments: e.target.value
                  }))
                }
              ></textarea>
            </div>

            <div className="mt-3">
              {/* Show the link for pdf preview */}
              {/* <input
                type="file"
                accept="application/pdf"
                onChange={(e) => setFile(e.target.files[0])}
              /> */}
              <button
                className="btn-sound"
                onClick={async () => {
                  // downloadFile();
                  await downloadFile();
                }}
              >
                Purchase Agreement
              </button>
              {/* <Link>See pdf</Link> */}

              {/* <div className="upload-box">
                <span>Upload Supporting Document</span>
                <i className="bi bi-cloud-upload ms-2"></i>
                <input
                  className="form-control-md"
                  id="formFileLg"
                  placeholder="Upload Supporting Document"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files?.[0]) {
                      // console.log(e.target.files?.[0]);
                      setOutput(null);
                      setSelectedFile(e.target.files[0]);
                      setOutputType(e.target.files[0].type);
                      selectImage(e.target.files[0]);
                      setIsImageUploadingOpen(true);
                    }
                  }}
                />
              </div> */}
            </div>
          </div>

          <div className="text-center">
            <button
              className="btn-sound next-btn w-100 mt-3"
              role="button"
              data-color="#3361a3"
              data-text="CONTINUE"
              onClick={(e) => handleClick(e)}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
