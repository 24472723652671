import "./EscrowInformation.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setLoader } from "../../store/slices/loaderSlice";
import { acceptEscrow, cancelEscrow, releaseEscrow } from "../../apis/escrow";
import { decrypytdata } from "../../apis/walletKey";
import { setResponse } from "../../store/slices/responseSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function EscrowInformation() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);
  const { toggleModal } = useContext(ModalOverlayContext);

  const [command, setCommand] = useState("");

  if (!location.state) {
    return navigate(-1);
  }

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const constants = {
    RELEASE_ESCROW: "RELEASE ESCROW",
    CANCEL_ESCROW: "CANCEL ESCROW",
    CREATE_MILESTONE: "CREATE MILESTONE",
    ACCEPT_ESCROW: "ACCEPT ESCROW",
    DECLINE_ESCROW: "DECLINE ESCROW"
  };

  const {
    TransactionTypecode,
    settlementCurrencyBalance,
    Displayname,
    CalendarDate,
    Escrow,
    TxHash,
    SentReceived
  } = location.state;

  async function handleCommandClick(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      let response = null;

      if (command === constants.RELEASE_ESCROW) {
        response = await releaseEscrow(APPID, APIKEY, {
          txHASH: TxHash
        });
      }

      if (command === constants.CANCEL_ESCROW) {
        response = await cancelEscrow(APPID, APIKEY, {
          txHASH: TxHash
        });
      }

      if (command === constants.ACCEPT_ESCROW) {
        response = await acceptEscrow(APPID, APIKEY, {
          txHASH: TxHash
        });
      }

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      const decryptedResponse = await decrypytdata(response.data, APIKEY);
      const data = JSON.parse(decryptedResponse);

      /* console.log(decryptedResponse);
      console.log(data); */

      dispatch(setResponse({ apiResponse: responseAPI, apiData: data }));
      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <p
            /* to="/activity" replace={true} */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-arrow-left-short"></i>
          </p>
          <h3>Escrow Information</h3>
        </div>

        <div className="summary-box escrow-information">
          <div className="summary-box-logo">
            <img src="/images/summary-logo.png" alt="logo" />
          </div>
          <h1 className="text-center">Escrow Information</h1>
          <div className="summary-list mb-3">
            <p>Escrow Tyoe</p>
            {/* <h5>Product</h5> */}
            <h5>{TransactionTypecode}</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Escrow Amount</p>
            {/* <h5>
              <span>$</span> 2,333.00 USD
            </h5> */}
            <h5>{settlementCurrencyBalance}</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Escrow Short Description</p>
            {/* <h5>
              <span>$</span> 2,333.00 USD
            </h5> */}
            <h5>{Displayname}</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Escrow Long Description</p>
            {/* <h5> Coffee purchased to be delivered by Freight on 11.22.33.</h5> */}
            <h5>{Escrow}</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Target Completion Date</p>
            {/* <h5>12/22/33</h5> */}
            <h5>{CalendarDate}</h5>
          </div>
          <div className="summary-list">
            <p>Purchase Agreement</p>
            <img src="/images/default.png" alt="default-img" />
          </div>
        </div>

        <div className="text-center">
          {SentReceived === "Sent" && (
            <button
              className="btn-sound next-btn w-100 mt-4 d-flex align-items-center ps-4 justify-content-between"
              data-bs-toggle="modal"
              data-bs-target="#RELEASE"
              to="/"
              onClick={() => {
                setCommand(constants.RELEASE_ESCROW);
              }}
            >
              <div>
                <i className="bi bi-upload me-2"></i>
                <span>RELEASE</span>
              </div>
              <i className="bi bi-arrow-right"></i>
            </button>
          )}

          {SentReceived === "Received" && (
            <button
              className="btn-sound next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
              data-bs-toggle="modal"
              data-bs-target="#RELEASE"
              to="/"
              onClick={() => {
                setCommand(constants.ACCEPT_ESCROW);
              }}
            >
              <div>
                <i className="bi bi-check2-square"></i>
                <span>ACCEPT</span>
              </div>
              <i className="bi bi-arrow-right"></i>
            </button>
          )}

          {/* <button
            className="next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
            data-bs-toggle="modal"
            data-bs-target="#RELEASE"
            to="/"
            onClick={() => {
              setCommand(constants.RELEASE_ESCROW);
            }}
          >
            <div>
              <img src="/icons/modify-icon.png" className="me-2" alt="icons" />
              <span>MODIFY</span>
            </div>
            <i className="bi bi-arrow-right"></i>
          </button> */}

          {SentReceived === "Received" && (
            <button
              className="btn-sound next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
              /* data-bs-toggle="modal"
            data-bs-target="#RELEASE"
            to="/"
            onClick={() => {
              setCommand(constants.RELEASE_ESCROW);
            }} */
            >
              <div>
                <img src="/icons/deny-icon.png" className="me-2" alt="icons" />
                <span>Decline</span>
              </div>
              <i className="bi bi-arrow-right"></i>
            </button>
          )}

          {/* <button
            className="next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
            data-bs-toggle="modal"
            data-bs-target="#RELEASE"
            to="/"
            onClick={() => {
              setCommand(constants.RELEASE_ESCROW);
            }}
          >
            <div>
              <img src="/icons/dispute-icon.png" className="me-2" alt="icons" />
              <span>DISPUTE</span>
            </div>
            <i className="bi bi-arrow-right"></i>
          </button> */}

          <button
            className="btn-sound next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
            /* data-bs-toggle="modal"
            data-bs-target="#RELEASE"
            to="/"
            onClick={() => {
              setCommand(constants.RELEASE_ESCROW);
            }} */
          >
            <div>
              <img
                src="/icons/milestones-icon.png"
                className="me-2"
                alt="icons"
              />
              <span>MILESTONES</span>
            </div>
            <i className="bi bi-arrow-right"></i>
          </button>

          {/* <button
            className="next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
            data-bs-toggle="modal"
            data-bs-target="#RELEASE"
            to="/"
            onClick={() => {
              setCommand(constants.RELEASE_ESCROW);
            }}
          >
            <div>
              <img src="/icons/request.png" className="me-2" alt="icons" />
              <span>REQUEST RELEASE</span>
            </div>
            <i className="bi bi-arrow-right"></i>
          </button> */}

          {SentReceived === "Sent" && (
            <button
              className="btn-sound next-btn w-100 mt-2 next-btn-last d-flex align-items-center ps-4 justify-content-between"
              data-bs-toggle="modal"
              data-bs-target="#RELEASE"
              to="/"
              onClick={() => {
                setCommand(constants.CANCEL_ESCROW);
              }}
            >
              <div>
                <i className="bi bi-x-lg"></i>
                <span>CANCEL</span>
              </div>
              <i className="bi bi-arrow-right"></i>
            </button>
          )}

          <div
            className="modal fade"
            id="RELEASE"
            tabIndex="-1"
            aria-labelledby="RELEASE"
            aria-hidden="true"
          >
            <div className="modal-dialog" style={{ position: "unset" }}>
              <div className="modal-box bg_gray">
                <div className="icons-fixed">
                  <img src="/icons/success-icon-modal.png" alt="icon" />
                </div>
                {/* <h2>Release Escrow</h2> */}
                <h2>{command}</h2>
                <div className="modal-list">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    {/* <h6>Milestone Type</h6>
                    <h5>Product</h5> */}
                    <h6>Escrow Type</h6>
                    <h5>{TransactionTypecode}</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    {/* <h6>Short Description</h6>
                    <h5>$ 2,333.00 USD</h5> */}
                    <h6>Short Description</h6>
                    <h5>{Displayname}</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <h6>Amount</h6>
                    <h5>$ 2,333.00 USD</h5> */}
                    <h6>Amount</h6>
                    <h5>{settlementCurrencyBalance}</h5>
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 mt-3">
                  <button
                    to="/"
                    className="btn-sound modal-btn me-3"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </button>
                  <button
                    to="/"
                    className="btn-sound modal-btn fill"
                    data-color="#3361a3"
                    data-text="RELEASE"
                    onClick={(e) => handleCommandClick(e)}
                  >
                    RELEASE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
