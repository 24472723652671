import { useState } from "react";
import { useRef } from "react";
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FROM_UMU } from "../../utils/conversion";

export default function CryptoAmount() {
  const amountInputElement = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { data: userData } = useSelector((state) => state.user);

  const [validationError, setValidationError] = useState("");
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const { PublicAddress: APPID, PrivateKey: APIKEY, Balance } = userData;
  const item = location.state;

  function handleChange(value) {
    let maxAmount = 10000000000.123456;

    if (Number(value) > maxAmount) return;

    if (value) {
      const amountValue = Number(value).toLocaleString("us-en");

      if (amountValue.length <= 16) {
        amountInputElement.current.style.fontSize = "32px";
      }

      if (amountValue.length > 16) {
        amountInputElement.current.style.fontSize = "26px";
      }
    } else {
      amountInputElement.current.style.fontSize = "32px";
    }

    setAmount(() => value);
  }

  function validate(amount) {
    if (!amount) return "Amount is required";
    if (isNaN(Number(amount))) return "Amount should be a valid number";
    if (!transactionId) return "Transaction ID is required";

    return null;
  }

  function handleSubmit() {
    const error = validate(amount);

    if (error) {
      setValidationError(error);
      return;
    }

    navigate("/add_funds_crypto_review", {
      state: { amount: Number(amount).toFixed(6, "0"), transactionId, item }
    });
  }

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <p
            role="button"
            onClick={() => navigate(-1)}
            className="btn-sound arrow-bg"
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-arrow-left-short"></i>
          </p>
          {/* <h3>ADD FUNDS</h3> */}
          {/* <h3>ADD UMU</h3> */}
        </div>

        <div className="payees-content">
          {/* <p className="mt-3 mb-0 text-white" style={{ fontSize: "12px" }}>
            You can exchange any supported crypto by attaching it to your wallet
            and sending funds to the listed wallet address.
          </p> */}

          <div className="enter-amount">
            {validationError && (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {validationError}
              </p>
            )}

            <div className="d-flex align-items-center my-2">
              <img
                className="payee_image"
                // src="/images/default.png"
                src={`images/crypto/${item.ImageFN}`}
                alt="default-img"
              />
              <CurrencyInput
                ref={amountInputElement}
                placeholder="0"
                className="form-control"
                defaultValue={"0"}
                value={amount}
                onValueChange={handleChange}
                decimalsLimit={6}
                allowNegativeValue={false}
                style={{ textAlign: "right", height: "55px" }}
              />
            </div>
          </div>

          <div className="my-2">
            <input
              type="text"
              className="mt-2 form-control rounded-end"
              placeholder="Paste Blockchain Transaction ID"
              id="walletInput"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />
          </div>

          <div className="text-center">
            <button
              className="btn-sound next-btn w-100 mt-4"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
