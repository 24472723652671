import { useRef, useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";

import "../payments/TransferReview.css";
import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";
import { uploadFile } from "../../apis/upload-download";
import { createLoan } from "../../apis/cashReserveNotes";
import { getDropdownValues } from "../../apis/dropdown";
import { decryptResponse } from "../../utils/decryptResponse";
import { setResponse } from "../../store/slices/responseSlice";

export default function CashReserveNotesReview() {
  const supportingDocument = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { toggleModal } = useContext(ModalOverlayContext);

  const { cashReserveNotes, setCashReserveNotes } = useOutletContext();

  const { data: userData } = useSelector((state) => state.user);

  const [supportingDocumentFile, setSupportingDocumentFile] = useState(null);

  const [paymentMethods, setPaymentMethods] = useState([]);

  /* useEffect(() => {
    setCashReserveNotes({
      documentFn: "4f0dae27-4887-46db-9c5d-efae63b4fc3bblob",
      refersTo: "originator",
      settlementCurrencyCode: "GBP",
      walletCurrencyCode: "INR",
      settlementQuotePrice: "1.350453",
      enteredAmount: "10",
      umuAmount: 13.504534822793492,
      walletAmount: 1025.1685230900534
    });
  }, []); */

  useEffect(() => {
    async function getPaymentMethods() {
      try {
        const lookUpResponse = await getDropdownValues("LKPaymentMethod");

        setPaymentMethods(lookUpResponse?.data?.commandDataset);
      } catch (error) {
        console.error(error);
      }
    }

    getPaymentMethods();
  }, []);

  const {
    refersTo,
    settlementCurrencyCode,
    walletCurrencyCode,
    settlementQuotePrice,
    enteredAmount,
    umuAmount,
    walletAmount
  } = cashReserveNotes;

  async function handleMakeTransaction(e) {
    try {
      showLoaderOnElement(e.target);
      toggleModal(true);

      let filePath = null;

      if (supportingDocumentFile) {
        const formData = new FormData();
        formData.append("File", supportingDocumentFile);
        formData.append("PublicAddress", userData?.PublicAddress);
        formData.append("IsPublic", false);

        const fileUploadResponse = await uploadFile(formData);

        if (fileUploadResponse?.data?.returnMessage !== "Success") {
          throw fileUploadResponse;
        }

        filePath = fileUploadResponse?.data?.commandResponse;
      }

      const data = {
        settlementCurrencycode: cashReserveNotes?.settlementCurrencyCode,
        settlementUnits: +Number(cashReserveNotes?.enteredAmount).toFixed(
          6,
          "0"
        ),
        paymentMethodcode: cashReserveNotes?.paymentMethodcode,
        comments: cashReserveNotes?.comments,
        referenceNumber: cashReserveNotes?.referencecode,
        agreementFN: cashReserveNotes?.documentFn,
        documentFN: filePath
      };

      const createLoanResponse = await createLoan(
        userData?.PublicAddress,
        userData?.PrivateKey,
        data
      );

      const { decryptedCreateLoanResponseObject, responseConfig } =
        await decryptResponse(createLoanResponse?.data, userData?.PrivateKey);

      console.log(decryptedCreateLoanResponseObject);

      dispatch(
        setResponse({
          apiResponse: responseConfig,
          apiData: decryptedCreateLoanResponseObject
        })
      );

      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <div className="email-address p-0">
      <div className="identification">
        <div className="container">
          <div className="header">
            <p
              style={{ cursor: "pointer" }}
              /* to="/transfer_amount" replace={true} */ role="button"
              onClick={() => navigate(-1, { replace: true })}
              className="arrow-bg"
            >
              <i className="bi bi-arrow-left-short"></i>
            </p>
            <h3>Summary</h3>
          </div>

          <div className="summary-box">
            <div className="summary-box-logo">
              <img src="/images/summary-logo.png" alt="logo" />
            </div>

            <h1 className="text-center">Summary</h1>

            <div className="summary-list mb-3">
              {refersTo === "originator" && <p>To</p>}
              {refersTo === "recipient" && <p>From</p>}
              {/* <h5 className=" mb-3">{name}</h5> */}
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Settlement</p>
                <h5>{settlementCurrencyCode}</h5>
              </div>
              <div className="summary-list text-end mb-3">
                <p>Settlement Amount</p>
                <h5>{Number(enteredAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>UMU Rate</p>
                <h5>{Number(settlementQuotePrice)?.toFixed(6, "0")}</h5>
              </div>

              <div className="summary-list mb-3 text-end">
                <p>UMU Amount</p>
                <h5>{Number(umuAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Wallet</p>
                <h5>{walletCurrencyCode}</h5>
              </div>

              <div className="summary-list mb-3 text-end">
                <p>Wallet Amount</p>
                <h5>{Number(walletAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>
          </div>

          <div>
            <h6 className="mt-3 text-white">Funding Information</h6>

            <select
              defaultValue=""
              onChange={(e) =>
                setCashReserveNotes((prev) => ({
                  ...prev,
                  paymentMethodcode: e.target.value
                }))
              }
            >
              <option value="" disabled>
                Payment Method
              </option>
              {paymentMethods?.map((row, index) => {
                return (
                  <option key={`${row?.ItemID}-${index}`} value={row?.Keycode}>
                    {row?.Displayname}
                  </option>
                );
              })}
            </select>

            <input
              type="text"
              className="form-control mt-3"
              placeholder="Reference Number"
              value={cashReserveNotes?.referencecode || ""}
              onChange={(e) => {
                setCashReserveNotes((prev) => ({
                  ...prev,
                  referencecode: e.target.value
                }));
              }}
            />

            <div>
              <textarea
                className="form-control mt-3"
                placeholder="Comments"
                rows="3"
                value={cashReserveNotes?.comments || ""}
                onChange={(e) => {
                  setCashReserveNotes((prev) => ({
                    ...prev,
                    comments: e.target.value
                  }));
                }}
              ></textarea>
            </div>

            <div className="mt-3">
              <div className="upload-box">
                <span>
                  {supportingDocumentFile
                    ? supportingDocumentFile?.name
                    : "Upload Proof of Funding"}
                </span>

                {!supportingDocumentFile && (
                  <i className="bi bi-cloud-upload ms-2"></i>
                )}

                <input
                  ref={supportingDocument}
                  className="form-control-md"
                  id="formFileLg"
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => {
                    setSupportingDocumentFile(e.target.files[0]);
                  }}
                />

                {supportingDocumentFile && (
                  <i
                    className="bi bi-x-lg close-icon"
                    onClick={() => {
                      supportingDocument.current.value = null;
                      setSupportingDocumentFile(null);
                    }}
                  ></i>
                )}
              </div>
            </div>
          </div>

          <div className="text-center">
            <button
              className="btn-sound next-btn w-100 mt-3"
              role="button"
              data-color="#3361a3"
              data-text="CONTINUE"
              onClick={(e) => handleMakeTransaction(e)}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
