import { hmacencrypt } from "./walletKey";
import { UMU_URL } from "../constants/config";
import { axios } from "../utils/axios-client";

export async function createLoan(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      `${UMU_URL}/money/CreateLoan`,
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/CreateLoan`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}
