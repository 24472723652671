export const WEB_WALLET_URL = "https://services.testnet.unicoinnetwork.com";

export const WEB_WALLET = "https://api.testnet.unicoinnetwork.com";

export const ADMIN_URL = "https://api.testnet.unicoinnetwork.com";

export const PORT = "8022";

export const ENCRYPTION_STRING =
  "<TF~@pJQVb9Le<4&L[9]jE5/DF.]nolqeU?C%s:@tI46u";

export const encryptionString = "<TF~@pJQVb9Le<4&L[9]jE5/DF.]nolqeU?C%s:@tI46u";

export const UMU_URL = "https://protocol.umu.testnet.unicoinnetwork.com";
export const UMU_SITE = "https://umu.testnet.unicoinnetwork.com";
