import "../wallet/Payees.css";
import "./AddUMU.css";

export default function AddUMUApps() {
  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <h3 className="ms-0 pt-3">ADD FUNDS</h3>
        </div>

        <div className="create-payee-page">
          <div className="text-center">
            <p>
              Only send funds if your payment app account name matches your
              wallet registration name.
            </p>

            <p className="m-0">$100.00 USD minimum purchase.</p>
          </div>

          <div className="table-bg table-content mt-4">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th style={{ width: "10px" }}></th>
                  <th></th>
                  <th>Send Funds to Wallet Address</th>
                  <th style={{ width: "10px" }}></th>
                </tr>
              </thead>
              <tbody>
                <tr id="row_container">
                  <td className="border-0">
                    <img
                      className="payee_image"
                      src="/icons/paypal-icon.png"
                      alt="dark-icon"
                    />
                  </td>
                  <td className="border-0">
                    <p className="mt-0 payee_name mb-0">PayPal</p>
                  </td>
                  <td className="border-0">
                    <span style={{ lineBreak: "anywhere" }}>
                      umu@unicoinnetwork.com
                    </span>
                  </td>
                  <td className="border-0">
                    <div
                      className="table-icon"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.target
                          .closest(".table-icon")
                          .classList.add("bg-success");
                        navigator.clipboard.writeText("umu@unicoinnetwork.com");
                        setTimeout(
                          () =>
                            e.target
                              .closest(".table-icon")
                              .classList.remove("bg-success"),
                          1500
                        );
                      }}
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  </td>
                </tr>

                <tr id="row_container">
                  <td className="border-0">
                    <img
                      className="payee_image"
                      src="/icons/cashapp-icon.png"
                      alt="dark-icon"
                    />
                  </td>
                  <td className="border-0">
                    <p className="mt-0 payee_name mb-0">Cashapp</p>
                  </td>
                  <td className="border-0">
                    <span style={{ lineBreak: "anywhere" }}>$UMUCASH</span>
                  </td>
                  <td className="border-0">
                    <div
                      className="table-icon"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.target
                          .closest(".table-icon")
                          .classList.add("bg-success");
                        navigator.clipboard.writeText("$UMUCASH");
                        setTimeout(
                          () =>
                            e.target
                              .closest(".table-icon")
                              .classList.remove("bg-success"),
                          1500
                        );
                      }}
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  </td>
                </tr>

                <tr id="row_container">
                  <td className="border-0">
                    <img
                      className="payee_image"
                      src="/icons/zelle-icon.png"
                      alt="dark-icon"
                    />
                  </td>
                  <td className="border-0">
                    <p className="mt-0 payee_name mb-0">Zelle</p>
                  </td>
                  <td className="border-0">
                    <span style={{ lineBreak: "anywhere" }}>
                      {/* umu@unicoinnetwork.com */}
                      {/* 3104668717 c/o Yobi Capital Fund */}
                      umu@unicoinnetwork.com
                      <br />
                      c/o Yobi Capital Fund
                    </span>
                  </td>
                  <td className="border-0">
                    <div
                      className="table-icon"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.target
                          .closest(".table-icon")
                          .classList.add("bg-success");
                        navigator.clipboard.writeText("umu@unicoinnetwork.com");
                        setTimeout(
                          () =>
                            e.target
                              .closest(".table-icon")
                              .classList.remove("bg-success"),
                          1500
                        );
                      }}
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
