import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import SolicitAmount from "../../components/SolicitAmount";

export default function CashReserveNotesAdd() {
  const navigate = useNavigate();

  const { cashReserveNotes, setCashReserveNotes } = useOutletContext();

  const [walletFxRates, setWalletFxRates] = useState(null);
  const [settlementFxRates, setSettlementFxRates] = useState(null);
  const [enteredAmount, setEnteredAmount] = useState(0);
  const [umuAmount, setUmuAmount] = useState(0);
  const [walletAmount, setWalletAmount] = useState(0);
  const [validationError, setValidationError] = useState("");

  function validate() {
    if (!enteredAmount) return "Please enter the amount";
    if (isNaN(+enteredAmount)) return "Amount should be a valid number";
  }

  async function handleContinue() {
    setValidationError("");

    const error = validate();
    if (error) {
      setValidationError(error);
      return;
    }

    const data = {
      refersTo: "originator",
      settlementCurrencyCode: settlementFxRates?.QuoteCurrencycode,
      walletCurrencyCode: walletFxRates.QuoteCurrencycode,
      settlementQuotePrice: Number(settlementFxRates?.QuotePrice)?.toFixed?.(
        6,
        "0"
      ),
      enteredAmount,
      umuAmount,
      walletAmount
    };

    setCashReserveNotes({ ...cashReserveNotes, ...data });

    navigate("/cash_reserve_notes_review");
  }

  return (
    <div className="p-0 email-address">
      <div className="container identification">
        <div className="header">
          <p
            className="arrow-bg btn-sound"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-arrow-left-short"></i>
          </p>
          <h3>Enter Funding Amount</h3>
        </div>

        <div>
          {validationError && (
            <p className="error_message text-danger">{validationError}</p>
          )}
          <SolicitAmount
            walletFxRates={walletFxRates}
            settlementFxRates={settlementFxRates}
            enteredAmount={enteredAmount}
            setWalletFxRates={setWalletFxRates}
            setSettlementFxRates={setSettlementFxRates}
            setEnteredAmount={setEnteredAmount}
            setUmuAmount={setUmuAmount}
            setWalletAmount={setWalletAmount}
          />
        </div>

        <div>
          <button
            style={{ width: "100%", margin: "16px 0" }}
            className="btn-sound btn next-btn"
            onClick={handleContinue}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </div>
  );
}
