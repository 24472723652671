import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";

import { getFxRatesApi2 } from "../apis/FxRatesApi";
import { FROM_UMU, TO_UMU } from "../utils/conversion";

export default function SolicitAmount({
  walletFxRates,
  setWalletFxRates,
  settlementFxRates,
  setSettlementFxRates,
  enteredAmount,
  setEnteredAmount,
  setUmuAmount,
  setWalletAmount
}) {
  const amountInputElement = useRef(null);

  const { data: userData } = useSelector((state) => state.user);

  const [fxRatesList, setFxRatesList] = useState([]);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const { PublicAddress: APPID, PrivateKey: APIKEY, Balance } = userData;

  const fontSize = (36 - amountInputElement.current?.value?.length || 0) * 1.4;

  useEffect(() => {
    getFxRatesList();
  }, []);

  useEffect(() => {
    setWalletFxRates(
      fxRatesList.find(
        (fxRate) => fxRate?.QuoteCurrencycode === userData?.Currencycode
      )
    );

    setSettlementFxRates(
      fxRatesList.find((fxRate) => fxRate?.QuoteCurrencycode === "UMU")
    );
  }, [fxRatesList]);

  useEffect(() => {
    const umuAmount = TO_UMU(settlementFxRates?.CoinPrice || 0, enteredAmount);
    const walletAmount = FROM_UMU(walletFxRates?.CoinPrice || 0, umuAmount);

    setUmuAmount(umuAmount);
    setWalletAmount(walletAmount);
  }, [enteredAmount, settlementFxRates, walletFxRates]);

  function handleChange(value) {
    let maxAmount = 10000000000.123456;

    if (Number(value) > maxAmount) return;

    setEnteredAmount(() => value);
  }

  function toggleOptions() {
    setIsOptionsOpen(() => !isOptionsOpen);
  }

  async function getFxRatesList() {
    try {
      const response = await getFxRatesApi2(APPID, APIKEY);

      setFxRatesList(response.data || []);
    } catch (error) {
      console.error(error);
    }
  }

  function changeSettlementRates(fxRate) {
    setSettlementFxRates(fxRate);
    setIsOptionsOpen(false);
    setUmuAmount(TO_UMU(settlementFxRates?.QuotePrice, enteredAmount));
  }

  return (
    <>
      <div className="enter-amount">
        <div className="d-flex align-items-center">
          <h1 className="mb-0 me-2">
            {settlementFxRates?.QuoteCurrencySymbol}
          </h1>

          <CurrencyInput
            ref={amountInputElement}
            placeholder="0"
            className="form-control text-end"
            defaultValue={0}
            value={enteredAmount}
            onValueChange={handleChange}
            decimalsLimit={6}
            allowDecimals={true}
            allowNegativeValue={false}
            style={{ height: "55px", fontSize: `${fontSize}px` }}
          />
        </div>

        <div
          className="enterp-amount-content"
          onClick={toggleOptions}
          style={{ cursor: "pointer" }}
        >
          <div className="box-logo me-3">
            <img src={settlementFxRates?.QuoteImageFN} alt="icons" />
          </div>

          <div className="form-floating">
            <div className="">
              <div className="text-light">
                <div>
                  <div className="font-size d-flex justify-content-between">
                    <div>
                      <span>1 Ü = </span>
                      <span>
                        {settlementFxRates?.QuoteCurrencySymbol}{" "}
                        {Number(settlementFxRates?.CoinPrice).toFixed(6, "0")}
                      </span>
                      <span className="ps-2">
                        {settlementFxRates?.Currencycode}
                      </span>
                    </div>

                    <span className="text-success me-2">
                      {settlementFxRates?.QuoteCurrencySymbol}{" "}
                      {Number(
                        TO_UMU(settlementFxRates?.QuotePrice, Balance)
                      ).toFixed(6, "0")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {isOptionsOpen ? (
              <i className="fa fa-angle-down text-white"></i>
            ) : (
              <i className="fa fa-angle-right text-white"></i>
            )}
          </div>
        </div>

        {isOptionsOpen && (
          <>
            <div className="dropdown-option" style={{ listStyleType: "none" }}>
              <div className="row align-items-center text-end">
                <div className="col-2"></div>
                <div className="col-4">
                  <span style={{ fontSize: "13px" }}>UMU RATE</span>
                </div>
                <div className="col-6">
                  <span style={{ fontSize: "13px" }}>BALANCE</span>
                </div>
              </div>

              {fxRatesList.map((option, index) => (
                <div
                  className="my-1 font-size"
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => changeSettlementRates(option)}
                >
                  <div className="row align-items-center mb-2">
                    <div className="col-2">
                      <img
                        className=""
                        src={option.QuoteImageFN}
                        style={{ width: "32px" }}
                      />
                    </div>

                    <div className="col-4 text-end d-flex justify-content-between">
                      <span>{option.QuoteCurrencySymbol}</span>
                      <span>{Number(option.CoinPrice).toFixed(4, "0")}</span>
                    </div>

                    <div className="col-6 ps-0 text-end d-flex justify-content-between">
                      <span className="text-success">
                        {option.QuoteCurrencySymbol}
                      </span>
                      <span className="text-success">
                        {Number(FROM_UMU(option.CoinPrice, Balance)).toFixed(
                          6,
                          "0"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              <div className="text-end">
                <Link
                  to="/add_fx_rates"
                  style={{
                    textDecoration: "none",
                    color: "lightblue",
                    fontSize: "12px"
                  }}
                >
                  Add more rates
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
